@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Thin.ttf');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraLight.ttf');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Black.ttf');
  font-weight: 900;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  font-weight: 500;
}